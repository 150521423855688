$sidebar-collapsed-width: 0px !default;
$sidebar-bg-color: #3F4D67 !default;
$sidebar-color: white !default;
$sidebar-width: 20vw !default;

.pro-sidebar {
  height:auto !important;
  z-index: 90 !important;
}

// .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
//   border-bottom: 0px solid rgba(255, 255, 255, 0.2) !important;
// }

@import '~react-pro-sidebar/dist/scss/styles.scss';