@font-face {
  font-family: 'Jost-Medium';
  src: local('OpenSans-Regular'), url(../fonts/OpenSans-Regular.ttf) format('ttf');
}

@import url('https://fonts.googleapis.com/css?family=Bungee+Inline');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');


* {
  margin: 0;
  

}

#root {
  display: flex;
}

body {
  font-family: 'Open Sans';
  margin: 0;
}

:root {
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace
}

/* background: linear-gradient(to top right, #2d4c82, #1b2843, #582a51); */

.allapp{
  background: linear-gradient(to top right, #2d4c82, #1b2843, #582a51);
}
/* 
.anchor {
  height: 100vh;
  width: 100vw;
  position: fixed;
} */

.headerWrapper {
  /* height: 100vh; */
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;
}

.header {
  padding-top: 10px;
  height: 10vh;
  width: 90%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menuLine {
  height: 4px;
  width:20%;
  background: linear-gradient(to top right, #fd3647, #d61557);
  border-radius: 3px;
  position: absolute;
  left: 2.5%;
  bottom: 0;
  animation-duration: 2s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    left: 2.5%;
  }

  to {
    left: 27.7%;
  }
}

.slideAnimation {

}

.oneBtnContainer {
  display: flex;
  flex-direction: column;
  /* background: #242729; */
}

.logo {
  height: 100%;
  /* width: 30%; */
}

.btnsContainer {
  width: 47%;
  position: relative;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-around;
  /* background: #04a9f5; */
}

.headerBtn {
background: none;
border: none;
color: white;
font-size: 22px;
font-weight:900;
/* opacity: 0; */
}

.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: linear-gradient(to top right, #fd3647, #d61557);
  border-radius: 10px;
  border: none;
}

.main {
  /* margin-top: -3%; */
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: transparent;

}

.mockup {
  width: 23vw;
  padding-top: 30px;
}


h2 {
  /* opacity:0; */
}

.leftText {
  width: 30%;
  color: white;
  text-align: center;
}

.rightText {
  width: 30%;
  color: white;
  text-align: center;

}

.txtInLgnBtn {
  opacity: 1;
  padding:0.5vw;
}

.adminPage {
  min-height: 100vh;
  width: 100vw;
  color: #353535;
  display: flex;
  position: relative;
  background-color: #F4F7FA;
}

.sideBarHeaderTitle {
  color: #fff;
  font-size: 22px;
  line-height: inherit;
  white-space: nowrap;
  font-family: 'Bungee Inline';

}

.sideBarHeaderLogo {
  height: 35px;
  width: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 8px;
  /* width: 30%; */
}

.sideBarHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width:90%;
  padding-left: 20px;
}

.adminHeader {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.adminHeaderWrapper {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;


}

.adminHeaderUserImage {
  width:40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 5px;
}

.adminHeaderUserBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dropDownMenu {
  width:22vw;
  height:150px;
  background-color: white;
  border-color: #EBEDEF;
  border-width: 2px;
  border-style: solid; /* Стиль границы */  
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
}

.dropDownUserOverlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dropDownMenuWrapper {
  position: absolute;
  right:1vw;
  top:60px;
  z-index: 10;
}

.dropDownUserWrapper {
  width: 100%;
  display: flex;
  height:70px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #2DC5FC;
}

.dropDownUserConatiner {
  width: 92%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dropdownAvatar {
  width: 50px;
  height: 50px;
  border-radius: 35px;
  margin-right: 15px;
}

.dropDownUserName {
  font-weight: 900;
  font-size: 14px;
  overflow: hidden;
  color: white;
  letter-spacing: 0.1px;
}


.dropDownDivider {
  width:95%;
  height:2px;
  display: inline-block;
  background-color: #EBEDEF;
}

.dropDownButtonOverlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height:65px;
}

.dropDownButton {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dropDownButtonText {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: normal;
  overflow: hidden;
  color: #959899;
  margin-left: 8px;
}

.authModal {
  width: 35vw;
  padding-top: 30px;
  padding-bottom: 30px;
  background: linear-gradient(to top right, #2d4c82, #1b2843, #582a51);
  border-radius:  20px
}

.customModal {
  width: 75vw;
  height: 60vh;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  border-radius:  5px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  z-index:100;

}


.customModalHeader {
  color: black;
  font-size: 22px;
  font-weight:900;
  text-align: center;
}

.customModalHeaderTwo {
  color: black;
  font-size: 22px;
  font-weight:500;
  text-align: center;
}


.authModalHeader {
  color: white;
  font-size: 22px;
  font-weight:900;
  text-align: center;
}

.authModalInputWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.authModalInput {
  border-width: 1px;
  width: 60%;
  height: 40px;
  border-color: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  background-color: rgba(23, 63, 112, 0.3);
  padding-left: 20px;
  margin-top: 20px;
  color:white;

}

.manageApplicationPlayerCommentInput {
  border-width: 1px;
  width: 25vw;
  padding-top: 0.5vw;
  padding-left: 0.5vw;
  height: 100px;
  width:97%;
  border-color: black;
  margin-top: 20px;
  color:black;
}

.authModalButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

}

.authModalButton {
  background: linear-gradient(to top right, #fd3647, #d61557);
  border-radius: 10px;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  width:20vw;
}

.authModalButtonText {
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 5px;
  opacity: 1
}

.authModalErrorTextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.authModalErrorText {
  color: #fd3647;;
  font-size: 16px;
  text-align: center;
  padding: 5px;
  opacity: 1
}

.sidebar-row {
  white-space: break-spaces;
  font-weight: 600;
}

.calendarHeaderOverlay {
  width: 100%;
  background-color: #EBEDEF;
  height: 40px;

  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1px;
  color: #242729;
  display: flex;
  justify-content: center;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: rgba(0, 0, 0,0.2);
  border-style: solid;
  border-left-width: 0px;
  border-right-width: 0px;
  position: sticky;
  top:70px;

}

.calendarHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width:95%;
  height:100%;
}

.calendarHeaderdate {
  width:20%;
  height:100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.calendarHeaderdateMatch {
  width:45%;
  height:100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.calendarHeaderdateStadium {
  width:15%;
  height:100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.calendarHeaderdateTime {
  width:10%;
  height:100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.calendarHeaderdateStatus {
  width:10%;
  height:100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.oneMatchOverlay {
  width: 100%;
  background-color: #EBEDEF;
  height: 70px;

  font-weight: bold;
  font-size: 1.1vw;
  letter-spacing: 1px;
  color: #242729;
  display: flex;
  justify-content: center;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: rgba(0, 0, 0,0.2);
  border-style: solid;
  border-left-width: 0px;
  border-right-width: 0px;

}

.oneMatchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width:95%;

}

.teamWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 45%;
  font-size: 15px;
  font-family: 'Jost-Medium';

}

.oneMatchTeamsWrapper {
  height:100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

.teamImage {
  width:35px;
  height: 35px;
  border-radius: 35px;
  margin-right: 10px;
  margin-left: 10px;

}

.teamRostersTeamImage {
  width:90px;
  height: 90px;
  border-radius: 90px;
  margin-top: 1vw;
}

.date {
  width:20%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.matchResult {
  width:10%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  
}

.matchStadium {
  width:15%;
  height:100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.matchStatus {
  width:10%;
  height:100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.matchTime {
  width:10%;
  height:100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.oneMatchOverlay:nth-child(2n){
  background-color: white;
}

.matchCenterWrapper {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgba(69,90,100,.08);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    width:90%;
    min-height:400px;
}

.layoutWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width:100%;
  max-width: 100%;
  

}

.matchCenterHeader {
  width:100%;
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left:50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #f1f1f1

}

.matchResultsHeader {
  width:94%;
  background-color: transparent;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f1
}

.matchCenterName {
  font-weight: bold;
}

.manageApplicationName {
  font-weight: bold;
}

.manageApplicationName:after {
  content: "";
  background-color: #04a9f5;
  position: absolute;
  left: 0px;
  top: 25px;
  width: 4px;
  height: 35px;
}

.blackListName {
  font-weight: bold;
}

.blackListName:after {
  content: "";
  background-color: #04a9f5;
  position: absolute;
  left: 0px;
  top: 15px;
  width: 4px;
  height: 35px;
}


.matchCenterName:after {
  content: "";
  background-color: #04a9f5;
  position: absolute;
  left: 0px;
  top: 15px;
  width: 4px;
  height: 20px;
}

.matchResultsName {
  font-weight: bold;
}

.matchResultsName:after {
  content: "";
  background-color: #04a9f5;
  position: absolute;
  left: 0px;
  top: 23px;
  width: 4px;
  height: 20px;
}

.dateWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 10px;
}

.calendarIconWrapper {
  width:30px;
  height:30px;
  position: relative;
  cursor: pointer;
}

.calendarIconWrapper:hover{
  color: #04a9f5;

}

.matcheCenterCalendarActive {
  color: #04a9f5;
}

.calendarIcon {
  width:30px;
  height:30px;
}

.dateNumber {
  position: absolute;
  font-size: 11.5px;
  font-weight: bold;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top:11.5px;
}

.matcheCenterMenuItem {
  margin-left: 15px;
  font-weight: bold;
  cursor: pointer;
}

.matcheCenterMenuItem:hover {
  color: #04a9f5;

}

.pageWrapper {
  margin-top: 20px;
}

.matcheCenterMenuItemActive {
  color: #04a9f5;
}

.matchCenterCalendarWrapper {
  left: 0;
  position: absolute;
  top:88px;
}

.react-calendar__tile--now {
  background: #f1f1f1 !important;
}


.react-calendar__tile--active {
  background: #04a9f5 !important;
}
.matcheCenterCalendarActive {
  display: block;
}

.emptyWrapper {
  height:100%;
  display: flex;
  align-items: center;
}

.blacklistAddPlayersPageEmptyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.emptyText {
  font-size: 18px;
}

.loadingOverlay {
  height:100%;
  display: flex;
  align-items: center;
}

.blacklistAddPlayersPageLoadingOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.loadingWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.table-responsive {
  display: block;
  width: 95%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.tableWrapper {
  width:100%;
  display: flex;
  justify-content: center;
  color: black;
}

.table td, .table th {
  border-bottom: 1px solid #eaeaea;
  white-space: nowrap;
  padding: 1.05rem .75rem;
  text-align: center;
}

.table td{
  font-size: 14px;
}

.matchTableTime {
  width:10%;
  height:100%;
}

.matchTableMatch {
  width:60%;
  height:100%;
}

.matchTableStadium {
  width:30%;
  height:100%;
}

.matchTableStatus{
  width:15%;
  height:100%;
}

.applicationTableNumber {
  width:10%;
  height:100%;
}

.applicationTableTeam {
  width:25%;
  height:100%;
  padding-right: 0 !important;
}

.applicationTableApplicationName {
  width:35%;
  height:100%;
}

.applicationTableDate {
  width:15%;
  height:100%;
}

.applicationTableStatus{
  width:15%;
  height:100%;
}

.matchCenterRow {
  cursor: pointer;

}

.matchCenterRow:hover {
  background-color: #F4F7FA;
}

.matchTeamsWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.matchTeamWrapper {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right:15%;
}

.applicationTeamWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right:15%;
}
.matchTeamGuestWrapper {
  justify-content: flex-start;
  margin-left:15%;
  margin-right:0;

}

.matchTeamImage {
  width: 60px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
}

.matchTeamText {
  font-weight: bold;
  font-size: 20px;
}

table { border-collapse: collapse; }


.matchHeadingText {
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.matchHeading {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.matchResultInputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.matchResultWrapper {
  position: absolute;
  left: 0;
  right: 0;
}

.matchResultDivider {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
}


.matchResultInput {
  border-width: 0;
  border-bottom-width: 1px;
  font-size: 25px;
  font-weight: bold;
  width: 50px;
  text-align: center;
}

.matchSubsTablesWrapper {
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}

.matchSubsTable {
  width: 50%;
}

.matchSubsColumn {
  width:5%;
  height:100%;
  border-width: 0px;
  border-right-width: 0.2px;
  border-style: solid;
}

.matchSubsPlayer {
  width:40%;
  height:100%;
  border-width: 0px;
  border-right-width: 0.2px;
  border-style: solid;
}

.matchSubsOneTable {
  border-width: 0px;
  border-left-width: 0.2px;
  border-top-width: 0.2px;
  border-bottom-width: 0.2px !important;
  border-style: solid;
  width:96%;
}

.matchSubsOneTable td, .matchSubsOneTable th {
  border-bottom: 1px solid black;
  white-space: nowrap;
  padding: 0.85rem .3rem;
  text-align: center;
}

.matchSubsTableOne {
  width: 100%;
  display: flex;
    justify-content: center;
}

.matchColumnInput {
  width: 15px;
  text-align: center;
}

.matchAdditionalInfoWrapper {
  width:100%;
  justify-content: center;
  display: flex;
}

.matchAdditionalInfoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width:90%;
  justify-content: space-around;
  flex-wrap: wrap;
}

.matchAdditionalInfoSelectHeadingWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.matchAdditionalInfoSelectHeading {
  color: #757575;
  font-size: 12px;
  margin-left: 2px;
  margin-bottom: 2px;
}


.matchAdditionalInfoCalendarWrapper{
  right: 25%;
  position: absolute;
  top: 150px;
  z-index: 2;
}

.matchAdditionalInfoDateTimeWrapper {
  height:36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 15%);
  border-radius: 3px;
}

.matchAdditionalInfoDateWrapper {
  display: flex;
  justify-content: center;
}

.matchAdditionalInfoDate {
  cursor: pointer;
  color: black;
  text-align: center;
}

.matchAdditionalInfoDateTimeDevider {
  width: 1px;
  height:  25px;
  background-color: #e3e3e3;
  margin-left: 10px;
  margin-right: 10px;
}

.matchAdditionalInfoTimeInput {
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #757575;
  width:18px;
  color: black;
  font-size: 15px;
}

.matchAdditinalInfoTimeWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.matchAdditionalInfoTime {
  cursor: pointer;
  color: #757575;
}

.emptyRow {
  border-width: 0px;
  border-right-width: 0.2px;
  border-style: solid;
}

.emptyRowText {
  font-weight: bold;
}

.matchChangeResultsButton {
  width: 10vw;
  background-color: #04a9f5;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  padding: 0.6rem .1rem;
  cursor: pointer;
}

.matchChangeResultsButtonDisabled {
  width: 10vw;
  background-color: rgba(4, 169, 245, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  padding: 0.6rem .1rem;
  cursor: pointer;
}

.matchChangeResultsButtonContainerWrapper {
  width: 100%;
  justify-content: center;
  display: flex;
}

.matchChangeResultsButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width:90%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}

.matchChangeDataResultsButton {
  width: 25vw;
  background-color: #04a9f5;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  padding: 0.6rem .1rem;
  cursor: pointer;
}

.matchChangeDataResultsButtonGreen {
  width: 25vw;
  background-color: rgba(33,152,95,0.7);
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  padding: 0.6rem .1rem;
  cursor: pointer;
}

.matchChangeResultsButtonText {
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.matchResultsModalWrapper {
  position: fixed;
  bottom:-100px;
  width:80%;
  height:80px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: bottom .5s;
  z-index: 100;
}

.matchResultsWarningWrapper {
  width:100%;
  display: flex;
  justify-content: center;
}

.matchResultsModalWrapperActive {
  bottom: -5px;
}

.bottomModalWrapperError {
  background-color: #F2DEDE;
  color: #AF514F;
}

.bottomModalWrapperSuccess {
  background-color: #DFF0D7;
  color: #629260;
}

.bottomModalWrapper {
  width: 100%;
  height: 100%;
  padding-top: 8px;
  border-radius: 10px;

}

.matchResultsWarningContent {
  width:100%;
  background-color: #FCF8E3;
  color: #8A6D3B;
  padding-top: 5px;
  padding-bottom: 5px;
}

.matchResultsErrorContent {
  width:100%;
  background-color: #E3242B;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bottomModalIcon {
  margin-left: 20px;
  margin-right: 10px;
}

.bottomModalRow {
  display: flex;
  width:150px;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 1px;
  justify-content: flex-start;
}

.matchResultsWarningRow {
  display: flex;
  width:100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
  justify-content: center;
}

.bottomModalHeading {
  font-weight: bold;
  font-size: 15px;
  margin-left: 10px;
}

.bottomModalRowLarge {
  width: 90%;
  margin-left: 55px;
}

.bottomModalText {
  font-size: 14px;
}

.emptyWarningBox {
  height: 59px;
  width:100%;
}

.matchResultsHint {
  margin-left: 1%;
}

.matchResultsHintWrapper {
  width:100%;
  margin-bottom: 60px;
  margin-top: 15px;

}

.select-search {
  width: 300px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding-inline-start: 0px;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #04a9f5;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(4, 169, 245, .3)
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #04a9f5;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #04a9f5;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.manageApplicationButtonsWrapper {
display: flex;
flex-direction: row;
align-items: center;
width:34vw;
justify-content: space-between;
}

.manageApplicationButtonsModalWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
  margin-top:20px;
}

.manageApplicationHeaderWrapper {
  width:'100%';
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Jost-Medium';

}

.manageApplicationHeaderRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 20px;
  border-bottom: solid rgba(0, 0, 0, 0.3) 1px;
}

.manageApplicationHeaderTeamInfoWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.teamRostersHeaderTeamInfoWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width:20vw;
  position: absolute;
  left: 0;
  top:90px
}

.teamRostersTablePhoto {
  width:100px;
  height:100px
}

.manageApplicationHeaderTeamInfoImage {
  width:80px;
  height: 80px;
  border-radius: 80px;
}

.manageApplicationHeaderTeamInfoImageWrapper {
  margin-bottom: 5px;
}

.manageApplicationHeaderTeamInfoName {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 3px;
}

.manageApplicationHeaderApplicationName {
  font-size: 18px;
  font-weight: bold;
  /* padding-bottom: 5px;
  border-bottom: solid rgba(0, 0, 0, 0.3) 1px; */
}

.manageApplicationHeaderCommentBlock {
  font-size: 16px;
}

.manageApplicationHeaderApplicationStatus {
  font-size: 18px;
  font-weight: bold;
  /* padding-bottom: 5px;
  border-bottom: solid rgba(0, 0, 0, 0.3) 1px; */
}

.manageApplicationHeaderApplicationCollapseValue {
  font-size: 16px;
}

.manageApplicationHeaderTextColumnWithHint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  text-align: center;
}

.manageApplicationHeaderHint {
  padding-top: 5px;
  margin-top: 5px;
  font-size: 16px;
  color: rgba(0, 0, 0,0.5);
  min-width: 250px;
  text-align: center;
  border-top: solid rgba(0, 0, 0, 0.3) 1px;

}

.manageApplicationHeaderCollapseHint {
  padding-top: 5px;
  margin-top: 5px;
  font-size: 15px;
  color: rgba(0, 0, 0,0.5);
  min-width: 250px;
  text-align: center;
  border-top: solid rgba(0, 0, 0, 0.3) 1px;
}

.manageApplicationHeaderNameWrapper {
  /* margin-left: 20px; */
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: inline-block;
  padding:5px;
  background-color: rgba(4, 169, 245,0.2);
  font-family: 'Jost-Medium';
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.manageApplicationPlayerWrapper {
  width:'100%';
  /* padding-top: 20px;
  padding-bottom: 20px; */
  font-family: 'Jost-Medium';
  border-bottom: solid rgba(0, 0, 0, 0.3) 1px;
}

.manageApplicationPlayerWrapperDisabled {
  width:'100%';
  /* padding-top: 20px;
  padding-bottom: 20px; */
  font-family: 'Jost-Medium';
  border-bottom: solid rgba(0, 0, 0, 0.3) 1px;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0.9;
}

.manageApplicationPlayerMainRow {
  width:'100%';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 20px; */
  padding-right: 30px;
  position: relative;
}

.manageApplicationPlayerImage {
  width:120px;
  height: 120px;
  object-fit: contain;
  background-color: rgba(0,0,0,0.1);
  
}

.manageApplicationPlayerImageWrapper {
  /* margin-right: 50px; */
}

.manageApplicationPlayerSubRow {
  width:'100%';
  display: flex;
  flex-direction: row;
  align-items:  flex-end;
  justify-content: space-between;
}

.manageApplicationPlayerCollapseRow {
  width:'100%';
  display: flex;
  flex-direction: row;
  align-items:  flex-end;
  justify-content: space-between;
  padding-left: 30px;
  padding-bottom: 30px;
  padding-top: 30px;
  padding-right: 30px;
}

.manageApplicationPlayerNameColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.manageApplicationPlayerButtonsColumn {
  display: flex;
  flex-direction: row;
  align-items:  center;
  justify-content: space-between;
}

.manageApplicationPlayerAcceptButtonsDisabled {
  color: rgba(0,0,0,0.4);
}

.manageApplicationPlayerAcceptButtonsEnabled {
  color: rgba(33,152,95);

}
.manageApplicationPlayerAcceptButtonsDisabled:hover {
  color: rgba(33,152,95);
}

.manageApplicationPlayerDeclineButtonsDisabled {
  color: rgba(0,0,0,0.4);
}

.manageApplicationPlayerDeclineButtonsEnabled{
  color: rgb(253,54,71);
}

.manageApplicationPlayerDeclineButtonsDisabled:hover {
  color: rgb(253,54,71);
}

.manageApplicationPlayerCommentButtonsEnabled {
  color: rgba(33,152,95);
}

.manageApplicationPlayerCommentButtonsDisabled {
  color:black;
}

.manageApplicationPlayerCommentButtonsDisabled:hover {
  color:gray;
}

.manageApplicationPlayerStatusAccept {
  padding:5px;
  background-color: #04a9f5;
  font-size: 16px;
  color:white;
  width:110px;
  text-align: center;
  font-family: 'Jost-Medium';
  /* color:rgba(0, 0, 0,0.8) */
}

.manageApplicationPlayerStatusDecline {
  padding:5px;
  background-color: rgb(253,54,71,1);
  font-size: 16px;
  color:white;
  width:110px;
  text-align: center;
  font-family: 'Jost-Medium';
}

.manageApplicationPlayerStatusChange {
  padding:5px;
  background-color: rgb(255,206,0);
  font-size: 16px;
  color:white;
  width:110px;
  text-align: center;
  font-family: 'Jost-Medium';
}

.manageApplicationPlayerCollapseTeamsHeader {
  font-family: 'Jost-Medium';
  font-size: 16px;
  font-weight: bold;
}

.manageApplicationPlayerCollapseTeamsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.manageApplicationPlayerCollapseTeamImage {
  width:30px;
  height: 30px;
  border-radius: 30px;
  margin-right: 5px;
  margin-left: 5px;
}

.manageApplicationPlayerCollapseTeamsHint {
  font-family: 'Jost-Medium';
  font-size: 14px;
  text-decoration: underline;
}

.manageApplicationPlayerCollapseTeamsHint:hover {
  color:grey
}

.manageApplicationPlayerCollapseTeamName {
  font-family: 'Jost-Medium';
  font-size: 15px;
  text-align: left;
}

.playerTeamsModalRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  border-bottom: solid rgba(0, 0, 0, 0.3) 1px;

}

.playerTeamsModalTeamName {
  width:22.5vw;
  text-align: center;
}

.playerTeamsModalApplicationName {
  width:30vw;
  text-align: center;

}

.playerTeamsModalLeagueName {
  width:22.5vw;
  text-align: center;

}

.playerTeamsModalTeamNameHeader {
  width:22.5vw;
  text-align: center;
  font-weight: bold;
}

.playerTeamsModalApplicationNameHeader {
  width:30vw;
  text-align: center;
  font-weight: bold;

}

.playerTeamsModalLeagueNameHeader {
  width:22.5vw;
  text-align: center;
  font-weight: bold;

}

.playerTeamsModalRowHeader {
  margin-top: 5vh;
  width:100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-bottom: solid rgba(0, 0, 0, 1) 2px;

}

.manageApplicationPlayerWraning {
  min-width:35vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  top:0
}

.simpleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.borderBottom {
  border-bottom: solid rgba(0, 0, 0, 0.2) 1px;

}

.manageApplicationHeaderButton {
  font-family: 'Jost-Medium';
  font-size: 17px;
  text-decoration: underline;
  cursor: pointer;
}

.manageApplicationHeaderButton:hover {
  color:grey

}

.ReactModal__Overlay--after-open {
  z-index: 2000;
}

.applicationManagmentCheckboxHint {
  font-family: 'Jost-Medium';
  font-size: 15px;
  color:black;
  margin-right: 10px;
}

.declineModalText {
  font-family: 'Jost-Medium';
  font-size: 15px;
  color:black;
  text-align: center;
}

.manageApplicationPopupRelatedTeamsWrapper {
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border: solid rgba(0, 0, 0, 0.2) 1.5px;

}

.manageApplicationPopupRelatedTeam{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-right: 20px;
}

.manageApplicationPopupRelatedTeamImage {
  width:50px;
  height: 50px;
  object-fit: contain;
}

.manageApplicationPopupRelatedTeamName {
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 3px;
  color:grey

}

.searchWrapper {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 3px solid #04a9f5;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: black;
}

.searchTerm:focus{
  color: black;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #04a9f5;
  background: #04a9f5;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.searchContainer{
  width: 30%;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.blacklistPlayerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 240px;
  height:385px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .25rem;
  overflow: hidden;
}

.blacklistPlayerContainerx {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 240px;
  height:385px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .25rem;
  overflow: hidden;
}

.blacklistPlayerContainerBrightness:hover {
  filter: brightness(75%);
}

.blackListPlayerTopMenuWrapper:hover + .blacklistPlayerContainerBrightness{
  filter: brightness(75%);

}

.blacklistPLayerPhotoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.blacklistAddPlayersPagePlayerPhotoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.blacklistPlayerPhoto {
  object-fit: cover;
  height: 300px;
  vertical-align: middle;
}

.blacklistAddPlayersPagePlayerPhoto {
  object-fit: cover;
  height: 70px;
  width:  70px;
  vertical-align: middle;
}

.blacklistAddPlayersPageTeamPhoto {
  object-fit: contain;
  height: 70px;
  width:  70px;
  vertical-align: middle;
}

.blackListPlayerInfoWrapper {
  flex: 1 1 auto;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.blackListPlayerFamilyWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  max-width: 200px;
  min-height: 50px;
}

.blacklistPlayerFamilyText {
  font-size: 15px;
  text-align: center;
}

.blacklistPlayerFamilyTextBold {
  font-weight: bold;
}

.blacklistPlayerLine {
  align-self: center;
  width:90%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 2px;
}

.blackListPlayerOneBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 200px;
}

.blackListPlayerOneBlockLabel {
  font-size: 13px;
  opacity: 0.8;
  font-style: italic;
}

.blackListPlayerOneBlockValue {
  font-size: 14px;
  text-align: center;

}

.blackListPlayerOneBlockValueWrapper {
  justify-content: center;
  display: flex;
}

.blacklistPlayerBlockData {
  margin-top: 15px;
  height:150px;
  overflow-y: scroll;
}

.blackListCollapsiblePart {
  position: absolute;
  bottom:-170px;
  background-color: #fff;

}

@keyframes big_it {
  from {bottom:-170px;} to {bottom:0px;}
}

@keyframes small_it {
  from {bottom:0px;} to {bottom:-170px;}
}

.ready_to_big {    
  animation-name: big_it;
  animation-timing-function:cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.ready_to_small_it {    
  animation-name: small_it;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.blackListPlayerTopMenuWrapper {
  background-color: transparent;
  width:85%;
  align-self: center;
  position: absolute;
  top:0;
  z-index: 100;
  visibility: hidden;
}

.blacklistPlayerContainerx:hover > .blackListPlayerTopMenuWrapper{
  visibility: visible;
}

.blackListPlayerTopMenuWrapperHidden {
  visibility: hidden !important;
}

.blackListPlayerTopMenu {
  width:100%;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.blackListPlayerTopMenuLeftButton {
  background-color: rgba(255, 255, 255, 0.85);
  width:50px;
  height:50px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-width: 0px;
}

.blackListPlayerCollapsibleButton {
  background-color: rgba(255, 255, 255, 0.85);
  width:35px;
  height:35px;
  border-radius: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-width: 0px;
}

.blackListPlayerCollapsibleButtonWrapper {
  position: absolute;
  top:-10px;
  right:0;
  opacity:0;
  visibility: hidden;
}

.blackListPlayerCollapsibleButtonOpacityAdd {
  animation-name: visible_it;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: visible;
}

.blackListPlayerCollapsibleButtonOpacityRemove {
  animation-name: hide_it;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes visible_it {
  from {opacity:0;} to {opacity:1;}
}

@keyframes hide_it {
  from {opacity:1;} to {opacity:0;}
}

.blackListPlayerOneBlockLabelWrapper {
  padding-bottom: 5px;
}

.blackListWrapperContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.blacklistAddPlayersPageWrapper {
  height: 100%;
  width:60%;
  background-color: white;
  position: fixed;
  z-index: 1003;
  display: block;
  top: 0;
  right: -60%;
}

@keyframes blacklistAddPlayersPageWrapper_open {
  from {right:-60%;} to {right:0px;}
}

@keyframes blacklistAddPlayersPageWrapper_close {
  from {right:0px;} to {right:-60%;}
}

.blacklistAddPlayersPageWrapperOpen {
  animation-name: blacklistAddPlayersPageWrapper_open;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.blacklistAddPlayersPageWrapperClose {
  animation-name: blacklistAddPlayersPageWrapper_close;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.blacklistAddPlayersCloseButton {
  background-color: rgba(255, 255, 255);
  width:50px;
  height:50px;
  border-radius: 50px;
  position: absolute;
  left:-20px;
  top:50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-width: 0px;
  opacity: 0;
}

.blacklistAddPlayersPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.blacklistAddPlayersPageHeadingText {
  font-weight: bold;
  font-size: 20px;

}

.blacklistAddPlayersPageSearchZoneWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:80%;
  align-self: center;
}

.blacklistAddPlayersPageSearchZoneSearch {
  width: 100%;
  border: 3px solid #04a9f5;
  padding: 5px;
  height: 20px;
  border-radius: 5px;
  outline: none;
  color: black;
}

.blacklistAddPlayersPageSearchZoneContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width:100%;
  align-self: center;
}

.blacklistAddPlayersPageErrorWrapper {
  height:40px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.blacklistAddPlayersPageErrorText {
  font-size: 14px;
  color:red;
}

.blacklistAddPlayersPagePlayerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
  justify-content: space-between;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left:20px;
  padding-right: 20px;
}

.blacklistAddPlayersPagePlayerWrapper:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.blacklistChosenPlayersPagePlayerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  justify-content: space-between;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left:20px;
  padding-right: 20px;
  background-color: rgba(0, 0, 0, 0.15);
  margin-top: 30px;
}

.blacklistAddPlayersPageContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
    align-items: center;

}

.blacklistAddPlayersPageScrollZone {
  overflow-y: scroll;
  height: 100%;
}

.blacklistAddPlayersPagePlayerIconWrapper {
  padding:5px;
  border-radius: 4px;
  background-color: #04a9f5;
  width:40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blacklistAddPlayersPagePlayerIcon {
  width:25px;
  height: 25px;
  object-fit: contain;
}

.blacklistAddPlayersPageInputZoneButtonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:60%;
}

.blacklistAddPlayersPageInputZoneContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width:100%;
}

.blacklistAddPlayersPageInputZoneWrapper {
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blacklistAddPlayersPageWrapperApplicationHeading {
  text-align: center;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
}